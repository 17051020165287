import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import BasicContent from "../components/BasicContent"
import BookBlock from "../components/BookBlock"
import ContactForm from "../components/ContactForm"

const Contact = props => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const basicContentData = {}
  basicContentData.titleTop = props.data.page.acf._dow_bc_title_top
  basicContentData.titleBot = props.data.page.acf._dow_bc_title_bot
  basicContentData.mainContent = props.data.page.acf._dow_bc_content
  basicContentData.flyPicker = props.data.page.acf._dow_bc_fly_picker

  const contactForm = {}
  contactForm.fluid =
    props.data.page.acf._dow_contact_form_image.localFile.childImageSharp.fluid
  contactForm.title = props.data.page.acf._dow_contact_form_title
  contactForm.content = props.data.page.acf._dow_contact_form_content

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      <BasicContent data={basicContentData} location="contact" />
      <ContactForm data={contactForm} />
      <BookBlock />
    </Layout>
  )
}

export const query = graphql`
  query contactPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_bc_title_top
        _dow_bc_title_bot
        _dow_bc_content
        _dow_bc_fly_picker

        _dow_contact_form_title
        _dow_contact_form_content
        _dow_contact_form_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Contact
