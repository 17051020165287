/* eslint-disable */
import React, { Component } from "react"
import styled from "styled-components"

import { fontSizer, colors, buttonOne } from "../Utilities"
import { submitTheForm } from "./FormUtilities/formFunctions"
import ErrorDisplay from "./FormUtilities/ErrorDisplay"
import SubmitDisplay from "./FormUtilities/SubmitDisplay"
import SuccessDisplay from "./FormUtilities/SuccessDisplay"

const FormContactStyled = styled.form`
  .form-submit-button {
    margin-top: 4rem;
    margin-left: 1rem;

    button {
      ${buttonOne};
      color: ${colors.colorPrimary};
      border-color: ${colors.colorPrimary};
      box-shadow: -1rem -1rem 0 0 ${colors.colorPrimary};
    }
  }
`

const InputFieldStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 3rem;

  @media (min-width: 768px) {
    width: calc(100% - 1.5rem);
  }

  label#input-fields-label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: block;
    width: 100%;
    color: ${colors.black};
  }

  p#field-error-message {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    position: absolute;
    top: 0.75rem;
    left: 0rem;
    color: red !important;
    z-index: 10;
  }

  input {
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 0.2rem solid rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};

    &::placeholder {
      color: ${colors.colorAccent};
    }
  }
`

const TextareaStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 3rem;

  label#input-fields-label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: block;
    width: 100%;
    color: ${colors.black};
  }

  p#field-error-message {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    position: absolute;
    top: 0.75rem;
    left: 0rem;
    color: red !important;
    z-index: 10;
  }

  textarea {
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 0.2rem solid rgba(0, 0, 0, 0.05);
    background-color: ${colors.white};

    &::placeholder {
      color: ${colors.colorAccent};
    }
  }
`

class FormContact extends Component {
  constructor(props) {
    super(props)
    this.onChangeFields = this.onChangeFields.bind(this)
    this.state = {
      fullName: "",
      yourEmail: "",
      message: "",
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    }
  }

  onChangeFields(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <FormContactStyled
        onSubmit={e => {
          this.setState(prevState => {
            return {
              ...prevState,
              submitting: true,
              errors: [],
            }
          }, submitTheForm(e, 2065, [{ fullName: this.state.fullName }, { yourEmail: this.state.yourEmail }, { message: this.state.message }], this.setState.bind(this)))
        }}
      >
        <InputFieldStyled>
          <label id="input-fields-label" htmlFor="fullName">
            Full Name
          </label>
          {this.state.errors.findIndex(error => {
            if (error.idref === "fullName") {
              return true
            }
          }) === -1 ? (
            ""
          ) : (
            <p id="field-error-message">Required Field</p>
          )}
          <input
            id="fullName"
            name="fullName"
            type="text"
            placeholder="Full Name"
            required={true}
            value={this.state.fullName}
            onChange={this.onChangeFields}
            errors={this.state.errors}
          />
        </InputFieldStyled>
        <InputFieldStyled>
          <label id="input-fields-label" htmlFor="yourEmail">
            Email
          </label>
          {this.state.errors.findIndex(error => {
            if (error.idref === "yourEmail") {
              return true
            }
          }) === -1 ? (
            ""
          ) : (
            <p id="field-error-message">Required Field</p>
          )}
          <input
            id="yourEmail"
            name="yourEmail"
            type="email"
            placeholder="Your Email"
            required={true}
            value={this.state.yourEmail}
            onChange={this.onChangeFields}
            errors={this.state.errors}
          />
        </InputFieldStyled>
        <TextareaStyled>
          <label id="input-fields-label" htmlFor="message">
            Message
          </label>
          {this.state.errors.findIndex(error => {
            if (error.idref === "message") {
              return true
            }
          }) === -1 ? (
            ""
          ) : (
            <p id="field-error-message">Required Field</p>
          )}
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            rows="5"
            required={true}
            value={this.state.message}
            onChange={this.onChangeFields}
            errors={this.state.errors}
          />
        </TextareaStyled>
        <div className="form-submit-button">
          <button>Submit</button>
        </div>
        {this.state.submitting && <SubmitDisplay />}
        {this.state.errorWarnDisplay && (
          <ErrorDisplay stateSetter={this.setState.bind(this)} />
        )}
        {this.state.success && (
          <SuccessDisplay stateSetter={this.setState.bind(this)} />
        )}
      </FormContactStyled>
    )
  }
}

export default FormContact
