import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import {
  medWrapper,
  colors,
  headlineOne,
  headlineThree,
  bodyCopy,
  fontSizer,
} from "../Utilities"
import FormContact from "./FormContact"

const ContactFormSection = styled.section`
  .contact-wrapper {
    ${medWrapper};
  }

  .contact-image {
    align-self: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 5rem);
      margin-right: 5rem;
    }

    .ci-img {
      width: 100%;
      margin-bottom: 1rem;
    }

    .ci-quote {
      background: ${colors.colorTertiary};
      text-align: center;
      width: 100%;
      padding: 5rem;

      .ci-quote__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 30rem;
        margin: auto;

        p {
          ${fontSizer(2, 3.2, 76.8, 150, 3)}
        }
      }

      h2 {
        ${headlineOne};
        width: 100%;
        margin: 0;
        margin-bottom: 1.5rem;
        color: ${colors.white};
        opacity: 0.65;
      }

      p,
      a {
        ${bodyCopy};
        width: 100%;
        margin: 0;
        color: ${colors.white};
        opacity: 0.65;
      }

      a {
        opacity: 1;

        &:hover {
          color: ${colors.black};
        }
      }

      .ci-info {
        margin-top: 2.5rem;

        p {
          text-transform: uppercase;
        }

        a {
          text-transform: lowercase;
        }
      }
    }
  }

  .content-form-fields {
    align-self: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 5rem);
      margin-left: 5rem;
    }

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorAccent};
      text-transform: uppercase;
    }
  }
`

const getData = graphql`
  query contactInfo {
    contactInfo: wordpressAcfOptions {
      options {
        dow_phone_number
        dow_email_address
      }
    }
  }
`

const ContactForm = ({ data }) => {
  const { fluid, title, content } = data
  const { contactInfo } = useStaticQuery(getData)
  return (
    <ContactFormSection>
      <div className="contact-wrapper">
        <div className="contact-image">
          <div className="ci-image">
            <Img fluid={fluid} alt={title} />
          </div>
          <div className="ci-quote">
            <div className="ci-quote__inner">
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="ci-info">
              <div className="ci-info__phone">
                <p>
                  Phone:{" "}
                  <a href={`tel:+1${contactInfo.options.dow_phone_number}`}>
                    {contactInfo.options.dow_phone_number}
                  </a>
                </p>
              </div>
              <div className="ci-info__email">
                <p>
                  email:{" "}
                  <a href={`mailto:${contactInfo.options.dow_email_address}`}>
                    {contactInfo.options.dow_email_address}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-form-fields">
          <div>
            <h3>Send a Message</h3>
          </div>
          <FormContact />
        </div>
      </div>
    </ContactFormSection>
  )
}

export default ContactForm
